import React, { useEffect, useState } from "react";
import { ModuleContainerProps } from "../../Models/ModuleContainer.generic";
import style from "./ModuleContainer.generic.module.less";
import classnames from "classnames";

export const ModuleContainer = ({ bgImageSrc, className, children, backgroundColor }: ModuleContainerProps) => {
    const hasBackgroundImage = bgImageSrc && bgImageSrc !== "";
    const backgroundClass = backgroundColor ? style[`${backgroundColor}Background`] : style.paperBackground;
    const outerClassName = classnames({
        [style.pageContentItem]: true,
        [backgroundClass]: true,
        [style.hasBackgroundImage]: hasBackgroundImage,
        [className]: true,
        "react-module-container": true,
    });

    return (
        <span>
            <div>
                <div
                    className={outerClassName}
                    style={hasBackgroundImage ? { backgroundImage: `url(${bgImageSrc})` } : undefined}
                >
                    <div className={style.container}>{children}</div>
                </div>
            </div>
        </span>
    );
};

export default ModuleContainer;
