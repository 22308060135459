import { get } from "lodash-es";
import Cookies from 'js-cookie';

export default class PageContext {
    constructor(data) {
        this.data = data || window.tradify;
    }

    get customerPictures() {
        return this.getValue("customerPictures");
    }

    get contentCountryCode() {
        return this.getValue("currentCountry.contentCountryCode");
    }

    get contentCountryId() {
        return this.getCountryByCode(this.contentCountryCode)?.hsId;
    }

    get currentCountry() {
        return this.getValue("currentCountry");
    }

    get countryCode() {
        return this.getValue("currentCountry.countryCode");
    }

    get currencyCode() {
        return this.getValue("currentCountry.currencyCode");
    }

    get currentCountryLanguageTag() {
        return this.getValue("currentCountry.languageTag");
    }

    get countries() {
        return this.getValue("countries");
    }

    get contentId() {
        return this.getValue("pageContext.contentId");
    }

    get features() {
        return this.getValue("features");
    }

    get featureDisplayRules() {
        return this.getValue("featureDisplayRules");
    }

    get hideHeaderNav() {
        return this.getValue("hideHeaderNav");
    }

    get industries() {
        return this.getValue("industries");
    }

    get canonicalSlug() {
        return this.getValue("currentPageMap.canonicalSlug");
    }

    get canonicalPageId() {
        return this.getValue("currentPageMap.canonicalPageId");
    }

    get pageLanguageTag() {
        return this.getValue("pageLanguageTag");
    }

    get pageMap() {
        return this.getValue("pageMap");
    }

    get portalId() {
        return this.getValue("portalId");
    }

    get portalIds() {
        return this.getValue("portalIds");
    }

    get portalInfo() {
        return this.getValue("portalInfo");
    }

    get marketingAutomationUrl() {
        return this.getValue("portalInfo.marketingAutomationUrl");
    }

    get analyticsDomain() {
        return this.getValue("portalInfo.analyticsDomain");
    }

    get ipCountryCode() {
        return this.getValue("pageContext.geoipCountry");
    }

    get webinarTypes() {
        return this.getValue("webinarTypes", []);
    }

    get testifyTests() {
        return this.getValue("testifyTests", []);
    }

    get testifyDataOnCompany() {
        return this.getValue("testifyDataOnCompany", "");
    }

    get pricingPlans() {
        return this.getValue("pricingPlans", []);
    }

    get contactPricingPlanTypeId() {
        return this.getIntegerValue("contactPricingPlanId");
    }

    get isTradifyUser() {
        return Cookies.get("_tradify_has_logged_in");
    }

    get hasLoggedIn() {
        return this.isTradifyUser;
    }

    get disableLocalizationByUrl() {
        return this.getBooleanValue("disableLocalizationByUrl");
    }

    get pageOptions() {
        return this.getValue("pageOptions", {});
    }

    get environment() {
        return {
            isProduction: () => this.portalId == this.portalIds.production,
            isQA: () => this.portalId == this.portalIds.qa,
            isDevelopment: () => this.portalId == this.portalIds.development,
            isDev3: () => this.portalId == this.portalIds.dev3,
            isSandbox: () => this.portalId == this.portalIds.sandbox,
        };
    }

    get staticBaseUrl() {
        return this.getValue('staticBaseUrl');
    }

    getValue(path, defaultValue) {
        return get(this.data, path, defaultValue);
    }

    getBooleanValue(path, defaultValue) {
        const value = this.getValue(path, defaultValue);

        if (typeof value === "string") {
            try {
                var parsedValue = JSON.parse(value);
                return parsedValue;
            } catch (err) {
                // Do nothing
            }
        }

        return value;
    }

    getIntegerValue(path, defaultValue) {
        return parseInt(get(this.data, path, defaultValue));
    }

    getCountryByIp() {
        let country = this.countries.filter(c => c.countryCode == this.ipCountryCode).pop();
        if (!country)
            country = this.countries.filter(c => c.countryCode == "global").pop();
        return country;
    }

    getCountryByCode(code) {
        return this.countries.filter(c => c.countryCode == code).pop();
    }

    isCountry(...countryCodes) {
        return countryCodes.find(c => c.toLowerCase() == this.countryCode);
    }
}