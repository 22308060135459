import { get, template } from "lodash-es";
import { ErrorHelper } from "../Helpers/ErrorHelper";

export default class LocalStrings {
    static get strings() {
        return get(window, ["tradify", "localStrings"], []);
    }

    static get(name) {
        return this.strings.find(s => s.name == name)?.value;
    }

    static getAsTemplate(stringKey, templateData) {
        let templateString = this.get(stringKey);
        if (!templateString) return "";

        let output = "";

        if (templateData) {
            try {
                output = template(templateString, {
                    interpolate: /&#123;&#123;([\s\S]+?)&#125;&#125;/g,
                })(templateData);
            } catch (error) {
                error.message = `Error parsing template string: ${stringKey} - ${error.message}`;
                console.error(error);
                ErrorHelper.sendError(error);
            }
        }

        return output;
    }
}