import React from 'react';
import PlanHeader from './PlanHeader';
import PlanFeatures from './PlanFeatures';
import PlanAddons from './PlanAddons';
import { AddOnOrFeature } from "./models/AddOnOrFeature";
import { AddOnsList } from './data/AddOnsList';
import { FeatureList } from './data/FeatureList';
import { PlanData } from './models/PlanData';
import style from "./styles/Plan.module.less";
import classnames from 'classnames';
import PlanCta from './PlanCta';

export interface PlanProps {
  planData: PlanData;
}

const Plan: React.FC<PlanProps> = ({
  planData,
}) => {

  const {
    pricingPlanId,
    isMostPopular,
    subscriptionPrice,
  } = planData;

  const buildFeatures = (allFeatures: AddOnOrFeature[]): AddOnOrFeature[] => {
    let prevTierLevel = -1;
    const features: AddOnOrFeature[] = [];

    const sortedFeatures = [...allFeatures].sort((a, b) => a.minimumPricingPlanId - b.minimumPricingPlanId);

    sortedFeatures.forEach((feature: AddOnOrFeature) => {
      if (feature.minimumPricingPlanId <= pricingPlanId) {
        if (prevTierLevel !== feature.minimumPricingPlanId && prevTierLevel !== -1) {
          // Create an empty feature with isSeparator = true
          features.push({
            minimumPricingPlanId: prevTierLevel,
            appStringKey: '',
            isSeparator: true,
          });
        }

        if (!feature.filterConditions || feature.filterConditions.every((condition) => condition())) {
          features.push(feature);
        }

        prevTierLevel = feature.minimumPricingPlanId;
      }
    });

    return features;
  };

  const mainClass = classnames(
    style.plan,
    { [style.mostPopular]: isMostPopular },
    `plan-${planData.pricingPlanId}`
  );
  return (
    <div className={mainClass}>
      <PlanHeader planData={planData} />
      <div className="plan-body">
        <PlanCta planData={planData} />
        <PlanFeatures features={buildFeatures(FeatureList)} />
        <PlanAddons addOns={buildFeatures(AddOnsList)} subscriptionPrice={subscriptionPrice} />
      </div>
    </div>
  );
};

export default Plan;
