import React from 'react';
import { AddOnOrFeature } from "./models/AddOnOrFeature";
import Tooltip from './ToolTip';
import LocalStrings from '../../Common/LocalStrings';
import { snakeCase } from 'lodash-es';
import { SubscriptionPrice } from './data/SubscriptionPrices';
import { formatCurrency } from './functions/formatCurrency';
import classNames from 'classnames';

export interface PlanAddOnsProps {
    subscriptionPrice: SubscriptionPrice;
    addOns: AddOnOrFeature[];
}

const PlanAddOns = ({ addOns, subscriptionPrice }: PlanAddOnsProps) => {

    const getTitle = (addOn: AddOnOrFeature): string => {
        return LocalStrings.get(`pricing_plan_add_on_${snakeCase(addOn.appStringKey)}_name`);
    }

    const getDescription = (feature: AddOnOrFeature): string => {

        let templateData: any = {
            websitePrice: formatCurrency(subscriptionPrice.websitePrice, subscriptionPrice.currencySymbol)
        };

        if (subscriptionPrice.sMSPrice) {
            templateData.smsPrice = formatCurrency(subscriptionPrice.sMSPrice, subscriptionPrice.currencySymbol);
        }

        try {
            return LocalStrings.getAsTemplate(`pricing_plan_add_on_${snakeCase(feature.appStringKey)}_description`, templateData);
        } catch (error) {
            // If a template variable is missing, the description will not be rendered and the error will be logged.
            const rg4js = (window as any)['rg4js'];
            rg4js && rg4js('send', {
                error: new Error(`Error getting add-on description for ${feature.appStringKey}`, {
                    cause: error,
                }),
            });
            return "";
        }
    };

    const getClass = (item: AddOnOrFeature): string | undefined => {
        return classNames(
            {
                'plan-addon': true,
                'separator': item.isSeparator,
                'show-tooltip': getDescription(item)
            });
    }

    return (
        <div className="plan-addons">
            {addOns.length > 0 && <p><strong>Optional Add-Ons</strong></p>}
            <ul>
                {addOns
                    .filter((addOn) => !addOn.isSeparator)
                    .map((addOn, index) => (
                        <li className={getClass(addOn)} key={index}>
                            <div className="addon-name">
                                <div dangerouslySetInnerHTML={{ __html: getTitle(addOn) }} />
                                {getDescription(addOn) && <Tooltip content={getDescription(addOn)} />}
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default PlanAddOns;
