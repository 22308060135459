import React from "react";
import ModuleContainer from "../Generic/ModuleContainer.generic";
import style from "./styles/SignupCta.module.less";
import LocalStrings from '../../Common/LocalStrings';
import Button from "../Generic/Button.generic";
import SafeHtml from './../Common/SafeHtml.common';

export const SignupCta = ({ }) => {

    const numberFormatted = LocalStrings.get("sales_number")
        ?.replace(/\s/g, '\u00A0');

    const plusCharacter = numberFormatted?.startsWith("1") ? "+" : "";
    const number = plusCharacter + numberFormatted?.replace(/\D/g, '');

    return (
        <ModuleContainer
            className={style.signupCta}
            backgroundColor="blue"
        >
            <div className={style.content}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className={style.headline}>
                                <h2>
                                    {LocalStrings.get("pricing_plans_signup_cta_headline")}
                                </h2>
                                <p>
                                    <SafeHtml html={LocalStrings.getAsTemplate("pricing_plans_signup_cta_sub_headline", {
                                        number,
                                        numberFormatted,
                                    })} />
                                </p>
                            </div>
                            <div className={style.signupCtaButton}>
                                <Button
                                    className={style.button}
                                    href={LocalStrings.get("signup_url")}>
                                    {LocalStrings.get("pricing_plan_cta")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModuleContainer>
    );
};

export default SignupCta;