export class ErrorHelper {

    public static get window() {
        return (window as any);
    }

    static sendError(error: Error) {
        if (this.window.rg4js) {
            this.window.rg4js('send', { error });
        }
    }
}
