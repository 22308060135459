import React, { useState } from 'react';
import classNames from 'classnames';
import { PlanData } from './models/PlanData';
import Plan from './Plan';

import style from "./styles/PlansMobile.module.less";

export interface PlanFeaturesProps {
    plans: PlanData[];
}


const PlansMobile = ({ plans }: PlanFeaturesProps) => {

    const defaultPlan = plans.find(plan => plan.isMostPopular) || plans[0];

    const [selectedPlan, setSelectedPlan] = useState<PlanData>(defaultPlan);

    const handleSelectPlan = (planData: PlanData) => {
        setSelectedPlan(planData);
    }

    return (
        <div className="plans-mobile">
            <div className={style.planSelector}>
                {plans.map((planData, index) => (
                    <div
                        key={index}
                        onClick={() => handleSelectPlan(planData)}
                        className={classNames(style.planOption, {
                            [style.selected]: selectedPlan === planData
                        })}
                    >
                        {planData.title}
                    </div>
                ))}
            </div>
            <Plan planData={selectedPlan} />
        </div>
    );
};

export default PlansMobile;
