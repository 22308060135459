import React from 'react';
import SafeHtml from './../Common/SafeHtml.common';

export interface TooltipProps {
    content: string;
}

const Tooltip = ({ content }: TooltipProps) => {
    return (
        <div className="css-tooltip">
            <div className="css-tooltip-inner">
                <SafeHtml html={content} />
            </div>
        </div>
    );
};

export default Tooltip;
