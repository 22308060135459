import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

interface SafeHtmlProps {
    html: string;
}

const SafeHtml = ({ html }: SafeHtmlProps) => {
    const cleanHtml = DOMPurify.sanitize(html, {
        ADD_ATTR: ['target'],
    });
    return <>
        {parse(cleanHtml)}
    </>;
};

export default SafeHtml;