import React from "react";
import style from "./Button.generic.module.less";
import classnames from "classnames";

interface ButtonProps {
    href?: string;
    status?: "primary" | "secondary";
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
}

export default function Button(props: ButtonProps) {
    const classNameFromProps = props.className ? props.className : "";
    const classNames = classnames({
        [style.button]: true,
        [style.primary]: props.status == "primary" || !props.status,
        [style.secondary]: props.status == "secondary",
        [classNameFromProps]: true,
    });

    if (props?.href) {
        return <a {...props} className={classNames} />;
    }

    return <button type="button" {...props} className={classNames} />;
}
