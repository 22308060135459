import React from 'react';
import { AddOnOrFeature } from "./models/AddOnOrFeature";
import Tooltip from './ToolTip';
import LocalStrings from '../../Common/LocalStrings';
import { snakeCase } from 'lodash-es';
import classNames from 'classnames';

export interface PlanFeaturesProps {
    features: AddOnOrFeature[];
}

const PlanFeatures = ({ features }: PlanFeaturesProps) => {

    const getTitle = (feature: AddOnOrFeature): string => {
        return LocalStrings.get(`pricing_plan_feature_${snakeCase(feature.appStringKey)}_name`);
    }

    const getDescription = (feature: AddOnOrFeature): string => {
        return LocalStrings.get(`pricing_plan_feature_${snakeCase(feature.appStringKey)}_description`);
    }

    const getClass = (feature: AddOnOrFeature): string | undefined => {
        return classNames(
            {
                'plan-feature': true,
                'separator': feature.isSeparator,
                'show-tooltip': getDescription(feature)
            });
    }

    return (
        <div className="plan-features">
            <ul>
                {features.map((feature, index) => (
                    <li className={getClass(feature)} key={index}                >
                        {!feature.isSeparator && (
                            <div className="feature-name">
                                <div dangerouslySetInnerHTML={{ __html: getTitle(feature) }} />
                                {getDescription(feature) && <Tooltip content={getDescription(feature)} />}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );


};

export default PlanFeatures;
