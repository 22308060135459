enum CountryCodes {
    AU = 'au',
    NZ = 'nz',
    IE = 'ie',
    GB = 'gb',
    CA = 'ca',
    ZA = 'za',
    GlobalAndUS = 'global', // US was removed as a country, we consider US to be a part of global
    FR = 'fr',
}

export default CountryCodes;
