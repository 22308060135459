import React from "react";
import ModuleContainer from "../Generic/ModuleContainer.generic";
import style from "./styles/SupportFeatures.module.less";
import LocalStrings from '../../Common/LocalStrings';
import BalancedText from "../Common/BalancedText.common";


export const SupportFeatures = ({ }) => {

    const features = Array.from({ length: 4 }).map((feature, index) => {
        return {

            title: LocalStrings.get(`support_feature_${index + 1}_title`),
            description: LocalStrings.get(`support_feature_${index + 1}_description`),
        }
    });

    return (
        <ModuleContainer
            className={style.supportFeatures}
            backgroundColor="blue"
        >
            <div className={style.content}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className={style.headline}>
                                <h2>
                                    <BalancedText text={LocalStrings.get("support_features_headline")} numberOfWords={3} />
                                </h2>
                            </div>
                            <div className={style.features}>
                                <div className={style.itemsContainer}>
                                    {features.map((feature, index) => {
                                        return (
                                            <div className={style.item} key={index}>
                                                <div className={style.title}>{feature.title}</div>
                                                <div className={style.description}>{feature.description}</div>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModuleContainer>
    );
};

export default SupportFeatures;