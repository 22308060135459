export interface SubscriptionPrice {
    price: number;
    noAccessPrice: number;
    currencyCode: string;
    currencySymbol: string;
    sMSPrice?: number;
    websitePrice: number;
    pricingPlanId: number;
}

export const SubscriptionPrices: SubscriptionPrice[] = [
    {
        "price": 45,
        "noAccessPrice": 11,
        "currencyCode": "NZD",
        "currencySymbol": "$",
        "sMSPrice": 0.2,
        "websitePrice": 19,
        "pricingPlanId": 12
    },
    {
        "price": 45,
        "noAccessPrice": 11,
        "currencyCode": "AUD",
        "currencySymbol": "$",
        "sMSPrice": 0.2,
        "websitePrice": 19,
        "pricingPlanId": 12
    },
    {
        "price": 49,
        "noAccessPrice": 11,
        "currencyCode": "NZD",
        "currencySymbol": "$",
        "sMSPrice": 0.2,
        "websitePrice": 19,
        "pricingPlanId": 13
    },
    {
        "price": 49,
        "noAccessPrice": 11,
        "currencyCode": "AUD",
        "currencySymbol": "$",
        "sMSPrice": 0.2,
        "websitePrice": 19,
        "pricingPlanId": 13
    },
    {
        "price": 59,
        "noAccessPrice": 11,
        "currencyCode": "NZD",
        "currencySymbol": "$",
        "sMSPrice": 0.2,
        "websitePrice": 19,
        "pricingPlanId": 14
    },
    {
        "price": 59,
        "noAccessPrice": 11,
        "currencyCode": "AUD",
        "currencySymbol": "$",
        "sMSPrice": 0.2,
        "websitePrice": 19,
        "pricingPlanId": 14
    },
    {
        "currencyCode": "CAD",
        "price": 59,
        "noAccessPrice": 11,
        "currencySymbol": "$",
        "websitePrice": 16,
        "pricingPlanId": 12
    },
    {
        "currencyCode": "CAD",
        "price": 65,
        "noAccessPrice": 11,
        "currencySymbol": "$",
        "websitePrice": 16,
        "pricingPlanId": 13
    },
    {
        "currencyCode": "CAD",
        "price": 77,
        "noAccessPrice": 11,
        "currencySymbol": "$",
        "websitePrice": 16,
        "pricingPlanId": 14
    },
    {
        "currencyCode": "EUR",
        "price": 36,
        "noAccessPrice": 7,
        "currencySymbol": "€",
        "websitePrice": 10,
        "pricingPlanId": 12
    },
    {
        "currencyCode": "EUR",
        "price": 40,
        "noAccessPrice": 7,
        "currencySymbol": "€",
        "websitePrice": 10,
        "pricingPlanId": 13
    },
    {
        "currencyCode": "EUR",
        "price": 47,
        "noAccessPrice": 7,
        "currencySymbol": "€",
        "websitePrice": 10,
        "pricingPlanId": 14
    },
    {
        "currencyCode": "GBP",
        "price": 32,
        "noAccessPrice": 6,
        "currencySymbol": "£",
        "sMSPrice": 0.1,
        "websitePrice": 10,
        "pricingPlanId": 12
    },
    {
        "currencyCode": "GBP",
        "price": 35,
        "noAccessPrice": 6,
        "currencySymbol": "£",
        "sMSPrice": 0.1,
        "websitePrice": 10,
        "pricingPlanId": 13
    },
    {
        "currencyCode": "GBP",
        "price": 42,
        "noAccessPrice": 6,
        "currencySymbol": "£",
        "sMSPrice": 0.1,
        "websitePrice": 10,
        "pricingPlanId": 14
    },
    {
        "currencyCode": "USD",
        "price": 45,
        "noAccessPrice": 10,
        "currencySymbol": "$",
        "sMSPrice": 0.08,
        "websitePrice": 12,
        "pricingPlanId": 12
    },
    {
        "currencyCode": "USD",
        "price": 49,
        "noAccessPrice": 10,
        "currencySymbol": "$",
        "sMSPrice": 0.08,
        "websitePrice": 12,
        "pricingPlanId": 13
    },
    {
        "currencyCode": "USD",
        "price": 59,
        "noAccessPrice": 10,
        "currencySymbol": "$",
        "sMSPrice": 0.08,
        "websitePrice": 12,
        "pricingPlanId": 14
    },
    {
        "currencyCode": "ZAR",
        "price": 450,
        "noAccessPrice": 110,
        "currencySymbol": "R",
        "websitePrice": 190,
        "pricingPlanId": 12
    },
    {
        "currencyCode": "ZAR",
        "price": 490,
        "noAccessPrice": 110,
        "currencySymbol": "R",
        "websitePrice": 190,
        "pricingPlanId": 13
    },
    {
        "currencyCode": "ZAR",
        "price": 590,
        "noAccessPrice": 110,
        "currencySymbol": "R",
        "websitePrice": 190,
        "pricingPlanId": 14
    }
]