import CountryCodes from "../../../Models/CountryCodes";
import PageContext from "../../PageContext";
import { AddOnOrFeature } from "../models/AddOnOrFeature";
import { PricingPlanEnum } from "../models/PricingPlanEnum";

const pageContext = new PageContext();

export const AddOnsList: AddOnOrFeature[] = [
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "Website"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "SMSQuoteInvoice",
        filterConditions: [
            () => pageContext.isCountry(CountryCodes.AU, CountryCodes.NZ, CountryCodes.GB),
        ]
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "SMSAppointmentReminders",
        filterConditions: [
            () => pageContext.isCountry(CountryCodes.AU, CountryCodes.NZ, CountryCodes.GB),
        ]
    }
]
