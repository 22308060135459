import React from "react";
import ModuleContainer from "../Generic/ModuleContainer.generic";
import style from "./styles/PricingPlanComparison.module.less";
import { BackgroundColor } from "~Types";
import { PricingPlanEnum } from "./models/PricingPlanEnum";
import Plan from './Plan';
import { PlanData } from "./models/PlanData";
import LocalStrings from './../../Common/LocalStrings';
import PageContext from './../PageContext';
import SupportFeatures from './SupportFeatures.block';
import SignupCta from "./SignupCta.block";
import PlansMobile from "./PlansMobile";

import('./styles/Fonts.less');
export interface PricingPlanComparisonBlockProps {
    backgroundColor?: BackgroundColor;
}

export const PricingPlanComparison = ({
    backgroundColor,
}: PricingPlanComparisonBlockProps) => {
    const pageContext = new PageContext();
    const currencyCode = pageContext.currencyCode;
    const mostPopularPlanId = PricingPlanEnum.tier2Monthly2023;
    const plans = getPricingPlans(currencyCode, mostPopularPlanId);


    return (
        <>
            <ModuleContainer
                className={style.pricingPlanComparison}
                backgroundColor={backgroundColor || "tradifyBlue"}
            >
                <div id="pricing-plans">
                    <div className="container">
                        <div className="row header-section">
                            <div className="col-xs-12">
                                <div className="page-title">
                                    <h1>
                                        {LocalStrings.get("pricing_plans_page_title")}
                                    </h1>
                                </div>
                                <div className="page-sub-title">{LocalStrings.get("pricing_plans_page_description")}</div>
                            </div>
                        </div>
                        <div className="row plans-mobile">
                            <PlansMobile plans={plans} />
                        </div>
                        <div className="row plans-section">
                            {plans.map((plan, index) => {
                                return (
                                    <div className="col-lg-4">
                                        <Plan key={index} planData={plan} />
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </ModuleContainer>
            <SupportFeatures />
            <SignupCta />
        </>
    );
};

export default PricingPlanComparison;

function getPricingPlans(currencyCode: string, mostPopularPlanId: PricingPlanEnum): PlanData[] {
    const plans = [
        new PlanData(currencyCode, PricingPlanEnum.tier1Monthly2023, "Tier1Monthly2023"),
        new PlanData(currencyCode, PricingPlanEnum.tier2Monthly2023, "Tier2Monthly2023"),
        new PlanData(currencyCode, PricingPlanEnum.tier3Monthly2023, "Tier3Monthly2023"),
    ];

    plans.forEach((plan) => {
        plan.isMostPopular = plan.pricingPlanId === mostPopularPlanId;
    });

    return plans;
}
