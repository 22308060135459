import React from 'react';
import { PlanData } from './models/PlanData';
import { formatCurrency } from './functions/formatCurrency';
import LocalStrings from '../../Common/LocalStrings';

export interface PlanHeaderProps {
    planData: PlanData;
}

const PlanHeader: React.FC<PlanHeaderProps> = ({ planData }) => {

    const price = formatCurrency(planData.subscriptionPrice.price);
    const symbol = planData.subscriptionPrice.currencySymbol;
    const perUser = LocalStrings.getAsTemplate("pricing_plan_per_user", { currencyCode: planData.subscriptionPrice.currencyCode });
    const exGst = LocalStrings.get('pricing_plan_ex_gst');
    const mostPopuplar = LocalStrings.get('pricing_plan_most_popular');


    return (
        <div className="plan-header">
            {planData.isMostPopular &&
                <div className="plan-most-popular">
                    <div className="ribbon">
                        <div className="text">{mostPopuplar}</div>

                    </div>
                </div>
            }
            <div className="content">
                <div className="plan-title">{planData.title}</div>
                <div className="plan-short-description" dangerouslySetInnerHTML={{ __html: planData.description }}></div>
                <div className="price">
                    <div className="price-text">
                        <div className="price-symbol">{symbol}</div>
                        <div className="price-number">{price}</div>
                    </div>
                </div>
                <div className="price-info">
                    {perUser}
                    {!planData.isTaxExempt && <div className="tax-info">{exGst}</div>}
                </div>

            </div>
        </div>
    );
};

export default PlanHeader;
