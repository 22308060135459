import { configureGoogleAnalytics } from "./configureGoogleAnalytics";
import { dataLayer } from "./dataLayer";

export default function getGtag(): Gtag.Gtag {
    const w: WindowObject = window;
    if (w.gtag) return w.gtag;
    const gtag: Gtag.Gtag = function () { dataLayer.push(arguments); }
    gtag('js', new Date());
    configureGoogleAnalytics(gtag);

    w.gtag = gtag;
    return gtag;
}
