import getGtag from './getGtag';
import PageContext from './../Components/PageContext';
import { dataLayerName } from './dataLayer';
export default class GoogleTagManager {

    static init() {

        // Make sure GA is configured first
        getGtag();

        // Load GTM script
        ((w, d, s, l, i) => {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0];
            var j = d.createElement(s);
            var dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = `${this.gtmDomain}/gtm.js?id=` + i + dl + this.envQueryString;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', dataLayerName, 'GTM-5DSPLZ');
    }

    static get gtmDomain() {
        if (this.context.analyticsDomain)
            return this.context.analyticsDomain;
        return 'https://www.googletagmanager.com';
    }

    static get context() {
        return new PageContext();
    }

    static get envQueryString() {

        if (this.context.environment.isDevelopment())
            return '&gtm_auth=LpHaGCsBjTqlvJhNSSz32w&gtm_preview=env-162&gtm_cookies_win=x';
        if (this.context.environment.isQA())
            return '&gtm_auth=hZ-I96_bJv74TlZ5g_uFUA&gtm_preview=env-129&gtm_cookies_win=x';
        return '';
    }

    static get gtag() {
        return getGtag();
    }

    static queueEvent(name, properties) {
        return new Promise((resolve) => {
            this.gtag('event', name, {
                event_callback: () => resolve(),
                ...properties,
            });
        });
    }

    static queuePageview(properties) {
        return new Promise((resolve) => {
            this.gtag('event', 'page_view', {
                event_callback: () => resolve(),
                ...properties,
            });
        });
    }
}
