import CountryCodes from "../../../Models/CountryCodes";
import PageContext from "../../PageContext";
import { AddOnOrFeature } from "../models/AddOnOrFeature";
import { PricingPlanEnum } from "../models/PricingPlanEnum";

const pageContext = new PageContext();

export const FeatureList: AddOnOrFeature[] = [
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "JobManagement"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "SchedulingPlusGoogleCalendarSync"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "QuotingAndInvoicing"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "PricingAndMarkups"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "OnlineAndCreditCardPayments"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "EmailTracking"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier1Monthly2023,
        appStringKey: "AccountingSoftwareSync"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "CustomBranding"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "EnquiryFormAndEmailInbox"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "CustomerCommunicationsHistory"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "JobPhotosAndFiles"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "RecurringJobsAndCustomStatuses"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "JobTemplates"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "AppointmentReminders"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "QuoteOptionsAndOnlineAcceptance"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "ProgressInvoicing"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "QuoteAndInvoiceReminders"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "QuoteAndInvoiceTemplates"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "CostingAndBillTracking"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "Timesheets"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "SubcontractorScheduling"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "HealthAndSafetyForms",
        filterConditions: [
            () => pageContext.isCountry(CountryCodes.NZ, CountryCodes.AU),
        ]
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier2Monthly2023,
        appStringKey: "ComplianceCertificates",
        filterConditions: [
            () => pageContext.isCountry(CountryCodes.NZ, CountryCodes.AU, CountryCodes.GB),
        ]
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier3Monthly2023,
        appStringKey: "JobTasks"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier3Monthly2023,
        appStringKey: "PurchaseOrders"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier3Monthly2023,
        appStringKey: "BulkInvoicing"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier3Monthly2023,
        appStringKey: "KitBundles"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier3Monthly2023,
        appStringKey: "StaffActivityHistory"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier3Monthly2023,
        appStringKey: "JobServiceReports"
    },
    {
        minimumPricingPlanId: PricingPlanEnum.tier3Monthly2023,
        appStringKey: "Reporting"
    }
]