import { SubscriptionPrice, SubscriptionPrices } from "../data/SubscriptionPrices";
import { PricingPlanEnum } from "./PricingPlanEnum";
import LocalStrings from '../../../Common/LocalStrings';
import { snakeCase } from "lodash-es";


export class PlanData {
    title: string;
    description: string;
    pricingPlanId: PricingPlanEnum;
    isMostPopular: boolean;
    subscriptionPrice: SubscriptionPrice;
    currencyCode: string;

    constructor(currencyCode: string, pricingPlanIdEnum: PricingPlanEnum, appStringKey: string) {
        this.currencyCode = currencyCode;
        this.title = this.getTitle(appStringKey);
        this.description = this.getDescription(appStringKey);
        this.pricingPlanId = pricingPlanIdEnum;
        this.isMostPopular = false;
        this.subscriptionPrice = this.getSubscriptionPrice(pricingPlanIdEnum, currencyCode);
    }

    get isTaxExempt(): boolean {
        return this.currencyCode !== 'NZD';
    }

    getTitle(appStringKey: string): string {
        return LocalStrings.get(`pricing_plan_display_${snakeCase(appStringKey)}_name`);
    }

    getDescription(appStringKey: string): string {
        return LocalStrings.get(`pricing_plan_display_${snakeCase(appStringKey)}_description`);
    }

    getSubscriptionPrice(pricingPlanId: PricingPlanEnum, currencyCode: string): SubscriptionPrice {

        var result = SubscriptionPrices.find((subscriptionPrice: SubscriptionPrice) => {
            return subscriptionPrice.pricingPlanId === pricingPlanId && subscriptionPrice.currencyCode === currencyCode;
        });

        if (!result)
            throw new Error(`SubscriptionPrice not found for PricingPlanId: ${pricingPlanId} and CurrencyCode: ${currencyCode}`);

        return result;
    }


}
