import React from 'react';
import SafeHtml from './SafeHtml.common';

interface BalancedTextProps {
    text: string;
    numberOfWords?: number;
}

const BalancedText = ({ text, numberOfWords = 2 }: BalancedTextProps) => {
    const balanceText = (value: string, numWords: number) => {
        if (!value) return "";
        if (value.split(" ").length <= numWords) return value;
        const words = value.split(" ");
        const lastWords = words.splice(-numWords);
        const lastWordsWithSpace = lastWords.join("&nbsp;");
        return [...words, lastWordsWithSpace].join(" ");
    }

    return <>
        <SafeHtml html={balanceText(text, numberOfWords)} />
    </>;
}

export default BalancedText;
