import React from 'react';
import { PlanData } from './models/PlanData';
import Button from '../Generic/Button.generic';
import LocalStrings from '../../Common/LocalStrings';

import style from "./styles/PlanCta.module.less";
import GoogleTagManager from '../../Tracking/GoogleTagManager';

export interface PlanCtaProps {
    planData: PlanData;
}

const PlanCta: React.FC<PlanCtaProps> = ({ planData }) => {

    const ctaText = LocalStrings.get('pricing_plan_cta');

    const handleClick = async () => {
        await GoogleTagManager.queueEvent(`Trial Button Clicked - Plan ${planData.title}`, {
            event_category: 'freetrial',
            event_label: 'Trial Signup Started'
        });
        window.location.href = LocalStrings.get('signup_url');
    }

    return (
        <div className={style.planCta}>
            <Button onClick={handleClick}>{ctaText}</Button>
        </div>
    );
};

export default PlanCta;
