export function configureGoogleAnalytics(gtag) {
    const analyticsDomain = window.tradify?.portalInfo?.analyticsDomain;
    const gtagOptions = {
        'transport_type': 'beacon',
        'custom_map': { 'dimension15': 'mkt_test' },
        'send_page_view': false,
        'debug_mode': checkDebugMode(),
    };

    if (analyticsDomain) {
        gtagOptions.transport_url = analyticsDomain;
        gtagOptions.first_party_collection = true;
    }

    // Enable requests to UA until processing stops
    if (Date.now() < 1688194800000) {
        gtag('config', 'UA-62166340-1', gtagOptions);
        gtag('config', 'UA-62166340-4', gtagOptions);
    }

    gtag('config', 'G-JFK5Q7MQ9L', gtagOptions); // Web
    gtag('config', 'G-R36SPPMVHW', gtagOptions); // Web & Apps

    gtag('set', {
        'ga4_id_added': true
    });
}

function checkDebugMode() {
    var debugMode = window.location.search.indexOf('ga4_debug_mode=1') > 0
        || document.cookie.indexOf('ga4_debug_mode=1') > 0;
    if (debugMode) console.log('GA4 Debug Mode');
    return debugMode;
}
